import { SHOW_ALTA_USER_MODAL } from "./actions";
import {tassign} from 'tassign'
export interface IAppState {
 altaUserModal: any;
}

export const INITIAL_STATE : IAppState = {
    altaUserModal: null
}

export function rootReducer (state, action){

    switch (action.type) {
        case SHOW_ALTA_USER_MODAL:
            return tassign(state, {altaUserModal: action.payload})
            break;
        default:
            break;
    }

    return state;
}