import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AltaUserComponent } from '../../user-manager/alta-user/alta-user.component';
import { LpSecurityDataService } from 'src/app/services/security/lp-security-data.service';
import { Subscription } from 'rxjs';
import { IAppState } from 'src/app/redux/store';
import { NgRedux } from '@angular-redux/store';
import { SHOW_ALTA_USER_MODAL } from 'src/app/redux/actions';
@Component({
  selector: 'app-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.css']
})
export class EditAccountComponent implements OnInit, AfterViewInit {

    public tmpAccountData;
    public accountData;
    public idEntityAccount;
    public idEntityUser;
    itemSelect = "INFORMATION";
    subscriptions: Subscription[] = [];
    messages: string[] = [];
  constructor(
    private ngRedux: NgRedux<IAppState>,
    public bsModalRef: BsModalRef,
    public bsModalRef2: BsModalRef,
    private _toast : ToastrService,
    private modalService: BsModalService,
    private securityService: LpSecurityDataService,
    
  )
  {

    
  }

    ngOnInit() {

    this.ngRedux.subscribe(() => {
      const store = this.ngRedux.getState();
      if(!store.altaUserModal.show){
        this.getListAccountsByIdEntity(this.tmpAccountData);
        this.bsModalRef2.hide();
  }
    })

  }
    

  ngAfterViewInit(): void {
    setTimeout(()=>{
      this.getListAccountsByIdEntity(this.bsModalRef.content.user);
    },500)
  }

  

  getListAccountsByIdEntity(data) {
    this.tmpAccountData = data;
    this.idEntityAccount = data.idEntityAccount;
    this.idEntityUser = data.idEntityUser;
    this.securityService.getListAccountsByIdEntity(data.idEntityAccount).subscribe((response: any) => {
     this.accountData = response[0];
     },
    error => {
      console.log(error);
    });

  }

  openModalAddUser(){
    this.bsModalRef2 = this.modalService.show(AltaUserComponent,  { class: 'modal-lg', backdrop: true, ignoreBackdropClick: true });
    const payload = {
      idEntityAccount: this.idEntityAccount,
      idEntityUser: this.idEntityUser
    }

    this.ngRedux.dispatch({
      type: SHOW_ALTA_USER_MODAL,
      payload: {
        userData: payload,
        show: true
    }
    })
    
  }

  closeModa(){
    this.bsModalRef.hide();
  }

}
