import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fxPeriod'
})
export class FxPeriodPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if(value != 0 && value != 1){
      return '1 Hr'
    }
    return '1 Minute'
  }

}
