import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { OrchestratorService } from '../../../services/orchestrator.service';
import { environment } from '../../../../environments/environment.prod';
import { Country } from 'src/app/models/countryModel';
import { ClientDataShared } from '../../services/lp-client-data.service';
import { ModalServiceLp } from 'src/app/components/services/lp-modal.service';
export interface StepState {
  currentStep: number;
}

@Component({
  selector: 'app-orchestrator',
  templateUrl: './orchestrator.component.html',
  styleUrls: ['./orchestrator.component.css']
})
export class OrchestratorComponent implements OnInit {

  itemsBreadcrumb: any = ['Home', 'Tools','Orchestrator'];
  todayDate = new Date();
  paymentsData: any = [];
  groupedData: Map<string, any> = new Map();
  highlightedPayments: any[] = [];
  ListCountries: any[] = []
  
  totalPaymentsSent: number = 0;
  totalGrossAmount: number = 0;
  totalNetAmount: number = 0;

  toggleFitro = true;
  // Filtros
  searchTerm: string = '';  // Para ticket, merchantId o lotOutId
  country: string;
  startDate: string = '';   // Fecha de inicio del rango
  endDate: string = '';     // Fecha de fin del rango
  timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone; // Detectar zona horaria

  constructor(
    private _paymentLogs : OrchestratorService,
    private clientData: ClientDataShared,
    private modalServiceLp: ModalServiceLp,
    
  ){
    const today = new Date();
    this.startDate = formatDate(today, 'yyyy-MM-dd', 'en');  // Fecha de inicio es hoy
    this.endDate = formatDate(today, 'yyyy-MM-dd', 'en');    // Fecha de fin es hoy
  }


  ngOnInit(): void {
    this.clientData.refreshItemsBreadCrumb(this.itemsBreadcrumb);
    this.getCountries();
    this.getFilteredPayments();
  }

  getCountries() {
    environment.Countries.map((item) => {
      let country = new Country({
        Code: item.Code,
        Name: item.Name,
        Currency: item.Currency,
        FlagIcon: item.FlagIcon,
        NameCode: item.NameCode,
        Description: item.Description
      })
      country.addIcon()
      this.ListCountries.push(country)
    });
  }

  getTotalNetAmount(item: any): number {
    return item.paymentsSent.reduce((total: number, payment: any) => total + payment.netAmount, 0);
  }

  isHighlighted(payment: any): boolean {
    if (!this.searchTerm) {
        return false; // Si no hay término de búsqueda, no se resalta
    }

    const searchLower = this.searchTerm.toLowerCase();
    return (
        payment.ticket.toLowerCase().includes(searchLower) ||
        payment.internalDescription.toLowerCase().includes(searchLower)
       
    );
}

  calculateDashboardStats() {
    // Inicializamos los totales
    this.totalPaymentsSent = 0;
    this.totalGrossAmount = 0;
    this.totalNetAmount = 0;

    // Iteramos sobre los items de paymentsData
    this.paymentsData.forEach((item: any) => {
        // Para cada item, sumamos la cantidad de paymentsSent
        this.totalPaymentsSent += item.paymentsSent.length;

        // Iteramos sobre paymentsSent para acumular los valores de grossValueClient y netAmount
        item.paymentsSent.forEach((payment: any) => {
            this.totalGrossAmount += payment.grossValueClient;
            this.totalNetAmount += payment.netAmount;
        });
    });
}


getFilteredPayments(): void {
  this.modalServiceLp.showSpinner();
  const params = {
    searchTerm: this.searchTerm || null,
    country: this.country || null,
    startDate: this.startDate ? formatDate(this.startDate, 'yyyy-MM-dd', 'en') : null,
    endDate: this.endDate ? formatDate(this.endDate, 'yyyy-MM-dd', 'en') : null,
    timeZone: this.timeZone
  };

  this._paymentLogs.getAll(params).subscribe({
    next: response => {
      this.paymentsData = response;
      this.calculateDashboardStats();
      setTimeout(() => { this.modalServiceLp.hideSpinner() }, 400)
    }, error: e => {
      setTimeout(() => { this.modalServiceLp.hideSpinner() }, 400)
      console.log(e)
    }, complete: ()=> {
      setTimeout(() => { this.modalServiceLp.hideSpinner() }, 400)
    }
  })

}
  getAllPayments(){
    this._paymentLogs.getAll().subscribe({
      next: response => {
        this.paymentsData = response;
        this.calculateDashboardStats();
      }, error: e => {
        console.log(e)
      }
    })
  }

}