export const environment = {
  production: false,
  urlApi:  'https://qa.localpayment.com/api/v2',
  urlRouter:  'https://qa.localpayment.com/ApiLpv2Router',
  baseHref: document.getElementsByTagName('base')[0].href,
  Countries: [
    { Code: 'ARG', Name: 'ARGENTINA', Currency: 'ARS', FlagIcon: null, NameCode: null, Description: null, active: true, currencies: [ {name: 'ARS'} , { name: 'USD'}], LocalTax: "1.20" },
    { Code: 'BOL', Name: 'BOLIVIA', Currency: 'BOB', FlagIcon: null, NameCode: null, Description: null, active: true, currencies: [ {name: 'BOB'} , { name: 'USD'}], LocalTax: "0" },
    { Code: 'BRA', Name: 'BRASIL', Currency: 'BRL', FlagIcon: null, NameCode: null, Description: null, active: true, currencies: [ {name: 'BRL'} , { name: 'USD'}], LocalTax: "0.38" },
    { Code: 'CHL', Name: 'CHILE', Currency: 'CLP', FlagIcon: null, NameCode: null, Description: null, active: true, currencies: [ {name: 'CLP'} , { name: 'USD'}], LocalTax: "0" },
    { Code: 'COL', Name: 'COLOMBIA', Currency: 'COP', FlagIcon: null, NameCode: null, Description: null, active: true, currencies: [ {name: 'COP'} , { name: 'USD'}], LocalTax: "0.40" },
    { Code: 'CRI', Name: 'COSTA RICA', Currency: 'CRC', FlagIcon: null, NameCode: null, Description: null, active: false, currencies: [ {name: 'CRC'} , { name: 'USD'}], LocalTax: "0" },
    { Code: 'ECU', Name: 'ECUADOR', Currency: 'USD', FlagIcon: null, NameCode: null, Description: null, active: true, currencies: [ { name: 'USD'}], LocalTax: "0" },
    { Code: 'SLV', Name: 'EL SALVADOR', Currency: 'USD', FlagIcon: null, NameCode: null, Description: null, active: false, currencies: [ { name: 'USD'}], LocalTax: "0" },
    { Code: 'GLO', Name: 'GLOBAL', Currency: 'USD', FlagIcon: null, NameCode: null, Description: null, active: true, currencies: [ { name: 'USD'}], LocalTax: "0" },
    { Code: 'MEX', Name: 'MÉXICO', Currency: 'MXN', FlagIcon: null, NameCode: null, Description: null, active: true, currencies: [ {name: 'MXN'} , { name: 'USD'}], LocalTax: "0" },
    { Code: 'PAN', Name: 'PANAMA', Currency: 'USD', FlagIcon: null, NameCode: null, Description: null, active: false, currencies: [ { name: 'USD'}], LocalTax: "0" },
    { Code: 'PRY', Name: 'PARAGUAY', Currency: 'PYG', FlagIcon: null, NameCode: null, Description: null, active: true, currencies: [ {name: 'PYG'} , { name: 'USD'}], LocalTax: "0" },
    { Code: 'PER', Name: 'PERU', Currency: 'PEN', FlagIcon: null, NameCode: null, Description: null, active: true, currencies: [ {name: 'PEN'} , { name: 'USD'}], LocalTax: "0.005" },
    { Code: 'URY', Name: 'URUGUAY', Currency: 'UYU', FlagIcon: null, NameCode: null, Description: null, active: true, currencies: [ {name: 'UYU'} , { name: 'USD'}], LocalTax: "0" } 
    
    
    
   
    
    
    
  ]
};
