import { Component, OnInit } from '@angular/core';
import { Transaction } from '../../model/transaction';
import { LpMasterDataService } from 'src/app/services/lp-master-data.service';
import { LpSecurityDataService } from 'src/app/services/security/lp-security-data.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ModalServiceLp } from '../../services/lp-modal.service';
import { ClientDataShared } from '../../services/lp-client-data.service';
@Component({
  selector: 'app-semaphore',
  templateUrl: './semaphore.component.html',
  styleUrls: ['./semaphore.component.css']
})
export class SemaphoreComponent implements OnInit {
  repeatedFlag: boolean = false;
  transactionsRepetead: Transaction[] = [];
  filesUpload: any[] = [];
  files: any;
  res: any = [];
  itemsBreadcrumb: any = ['Home', 'Tools', 'Semaphore'];
  action: string;
  stateLoad: boolean = false;
  stateValidation: boolean = false;
  stateUpload: boolean = false;
  modifyInclude: boolean = true;
  modifyExclude: boolean = true;
  finalState: boolean = null;
  hasLotAmountMaxLimit = false;    // define if the provider has a maximum limit by lot 
  maxAmountLimitProvider = 0;  // if provider has a limit of amount by lot 

  fileBase64: any;
  
  ListTransactions: any = [{ name: 'Providers Payment', val: '2' }]
  //TODO agregar verdadero provider de Uruguay
  ListProviders: any[] = [];
  ListFx: any = [{ name: 'With Fx', code: 'WITH_COT' }, { name: 'Without Fx', code: 'WITHOUT_COT' }]
  inputElementFile: HTMLInputElement;
  MexOperationsFilter: any[] = [{
    value: 1,
    label: "Received"
  },
  {
    value: 2,
    label: "Approved Accounts"
  },
  {
    value: 3,
    label: "Pending PreRegister Response"
  }]


  dataTxt: string = "";
  dataTxtBenef: string = "";
  dataTxtBrou: string = "";

  downloadStatus: string = null;
  userPermission: string = this.securityService.userPermission;

  listTransactionsUpload: any = [];

  ErrorTrans: number = 0;
  OkTrans: number = 0;
  PendingTrans: number = 0;
  IgnoredTrans: number = 0;
  //Filters
  countryCode: string = null
  dollarPrice: string = "";
  actionSelect: string = "";
  trSelect: any = null;
  merchantSelect: any = null;
  subMerchantSelect: any = null;
  mexOperationType: any = 1;
  mexIncludeDupAmounts: boolean = false;
  txLimit: any = null;
  txMaxAmount: any = null;

  providerSelect: any = null;
  bankIncludeSelect = new Array();
  bankExcludeSelect = new Array();
  currencySelect: string = "";
  amountLimit: string = ""
  downloadDateTo: string = "";
  downloadHour: string = "";
  countFilteredPayouts: number = 0;
  filteredPayouts: any[] = [];
  bsConfig: Partial<BsDatepickerConfig>;
  filteredPayoutsTotal: any;
  checkedPayouts: any[] = [];
  checkedPayoutsTotal: any;
  rejectAll: boolean = false;
  maxAmountExceeded = false;
  minAmountRequired = false;

  
  constructor(
    private clientData: ClientDataShared,
    private modalService: BsModalService,
    private modalServiceLp: ModalServiceLp,
    private securityService: LpSecurityDataService,
    private LpServices: LpMasterDataService,
    ) {
  }

  ngOnInit() {
    this.bsConfig = Object.assign({}, { containerClass: 'theme-default', dateInputFormat: 'DD/MM/YYYY', showWeekNumbers: false, clearBtn: true });
    this.clientData.refreshItemsBreadCrumb(this.itemsBreadcrumb);
    this.action = 'upload';
    this.trSelect = this.ListTransactions[0].val;
  }
  

  filter(mifelInternal = false) {
    this.modalServiceLp.showSpinner();
    this.rejectAll = false;
    this.countFilteredPayouts = 0;
    this.filteredPayouts = [];
    let dateTo = new Date(this.downloadDateTo);
    var params = {
      'lockedV3': true
    }
    

    this.LpServices.Payout.listLockedV3().subscribe((data: any) => {
      if (data && data.length > 0) {
        this.downloadStatus = null
        this.countFilteredPayouts = data.length;
        this.filteredPayouts = data.slice(0, 1000);
        var total = {
          'GrossValueClient': this.filteredPayouts.reduce((a, b) => parseFloat(a) + (parseFloat(b['GrossValueClient']) || 0), 0),
          'LocalTax': this.filteredPayouts.reduce((a, b) => parseFloat(a) + (parseFloat(b['LocalTax']) || 0), 0),
          'TaxWithholdings': this.filteredPayouts.reduce((a, b) => parseFloat(a) + (parseFloat(b['TaxWithholdings']) || 0), 0),
          'TaxWithholdingsARBA': this.filteredPayouts.reduce((a, b) => parseFloat(a) + (parseFloat(b['TaxWithholdingsARBA']) || 0), 0),
          'NetAmount': this.filteredPayouts.reduce((a, b) => parseFloat(a) + (parseFloat(b['NetAmount']) || 0), 0),
        };
        this.filteredPayoutsTotal = total;
      }
      else {
        this.downloadStatus = "NOTPROCESS";
      }
      setTimeout(() => { this.modalServiceLp.hideSpinner() }, 400)

    },
      error => {
        setTimeout(() => { this.modalServiceLp.hideSpinner() }, 400)
        this.downloadStatus = "ERROR";

      });
  }

  loadAllTransactions(rejected: boolean) {
    console.log(rejected);
    this.checkedPayouts = [];
    if (rejected) {
      this.filteredPayouts.forEach((Payout: any) => {
        console.log("[Mostrando payouts]", Payout);
        Payout.Reject = true;
          this.checkedPayouts.push(Payout);
        if (Payout.HistoricalyRepetead || Payout.Repeated) {
          this.repeatedFlag = true;
          this.transactionsRepetead.push(Payout);
        }
      });
    }
    else {
      this.filteredPayouts.forEach(Payout => {
        Payout.Reject = false;
        if (Payout.HistoricalyRepetead || Payout.Repeated) {
          this.transactionsRepetead = this.transactionsRepetead.filter(obj => obj !== Payout);
          if (this.transactionsRepetead.length == 0)
            this.repeatedFlag = false;
        }
      });
    }

    this.loadCheckboxTotals();
  }

  loadCheckboxTransactions(Transaction: any, rejected: boolean) {
    if (rejected) {
      this.checkedPayouts.push(Transaction)
      if (Transaction.HistoricalyRepetead || Transaction.Repeated) {
        this.repeatedFlag = true;
        this.transactionsRepetead.push(Transaction);
      }
    }
    else {
      let index = this.checkedPayouts.indexOf(Transaction);
      this.checkedPayouts.splice(index, 1);
      if (Transaction.HistoricalyRepetead || Transaction.Repeated) {
        this.transactionsRepetead = this.transactionsRepetead.filter(obj => obj !== Transaction);
        if (this.transactionsRepetead.length == 0) {
          this.repeatedFlag = false;
        }
      }
    }
    this.checkAll();
    this.loadCheckboxTotals();
  }

  loadCheckboxTotals() {
    var total = {
      'GrossValueClient': this.checkedPayouts.reduce((a, b) => parseFloat(a) + (parseFloat(b['GrossValueClient']) || 0), 0),
      'LocalTax': this.checkedPayouts.reduce((a, b) => parseFloat(a) + (parseFloat(b['LocalTax']) || 0), 0),
      'TaxWithholdings': this.checkedPayouts.reduce((a, b) => parseFloat(a) + (parseFloat(b['TaxWithholdings']) || 0), 0),
      'TaxWithholdingsARBA': this.checkedPayouts.reduce((a, b) => parseFloat(a) + (parseFloat(b['TaxWithholdingsARBA']) || 0), 0),
      'NetAmount': this.checkedPayouts.reduce((a, b) => parseFloat(a) + (parseFloat(b['NetAmount']) || 0), 0),
    };
    this.checkedPayoutsTotal = total;
  }

  checkAll() {
    this.rejectAll = this.checkedPayouts.length == this.filteredPayouts.length ? true : false
  }

  formatNumber(numAmount: string): string {
    if(numAmount == null)
     return null
    var newMonto = numAmount;
    newMonto = newMonto + "00000";
    return newMonto;
  }
  validateNumber(value: any) {
    if (value != "") {
      var regexNumber = /^\d*\.?\d*$/;
      return regexNumber.test(value);
    }
    else {
      return true;
    }
  }

  handleUnlockTrx(){
    this.modalServiceLp.showSpinner();

    let tickets = this.checkedPayouts.map((data)=>data.Ticket);
    this.LpServices.Payout.unlockedV3(tickets).subscribe((data: any) => {
      this.filteredPayouts = [];
      this.checkedPayouts = [];
      this.downloadStatus = "OK";
      setTimeout(() => { this.modalServiceLp.hideSpinner() }, 400)
    }, error => {
      console.log("Error", error)
      setTimeout(() => { this.modalServiceLp.hideSpinner() }, 400)
    });
  }

}
