import { Component, OnInit } from '@angular/core';
// import { ProviderCycle } from 'src/app/models/providerCycle';


@Component({
  selector: 'app-close-cycle-provider',
  templateUrl: './close-cycle-provider.component.html',
  styleUrls: ['./close-cycle-provider.component.css']
})
export class CloseCycleProviderComponent implements OnInit {
  // providerCycle:ProviderCycle;
  providerCycle:any;
 
  constructor() { }

  ngOnInit() {

  }
  
 
  

}
