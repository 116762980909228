import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { LpMasterDataService } from 'src/app/services/lp-master-data.service';
import { ClientDataShared } from '../../../services/lp-client-data.service';
import { ModalServiceLp } from '../../../services/lp-modal.service';
import { environment } from '../../../../../environments/environment.prod';
import { Country } from 'src/app/models/countryModel';
import { ToastrService } from 'ngx-toastr';
import { LpSecurityDataService } from 'src/app/services/security/lp-security-data.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.css']
})
export class AddAccountComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() inputData : any

  myForm: FormGroup;
  myFormArray: FormArray;
  itemsBreadcrumb: any = ['Home', 'Tools', 'Create Account'];
  ListMainCountries: any[] = [];
  ListMainCurrencies: any[] = [];
  ListCountries: any[] = [];
  ListCurrenciesFilter: any[] = [];
  ListCountriesSelected: any[] = [];
  public accountExists: boolean = false;
  public loading: boolean = false;
  public responseAPI: any = null;
  public AccountDataTextArea: any;
  public locationUrl: any = window.location.origin;
  copied = false;

  constructor(
    private clientData: ClientDataShared,
    private securityService: LpSecurityDataService,
    private modalService : ModalServiceLp,
    private LpServices: LpMasterDataService,
    public bsModalRef: BsModalRef,
    public fb: FormBuilder,
    private _toast : ToastrService,
  ) {
    this.myForm = this.fb.group({
      MerchantName: ['', Validators.required],
      Mail: ['', [Validators.required, Validators.pattern("^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$")]],
      DataCountries: this.fb.array([]),
    });
    
  }


  patchValueForm(inputData){
    this.myForm.patchValue({
      MerchantName: inputData.MerchantName,
      SubMerchantName: inputData.SubMerchantName,
      Mail: inputData.Mail,
    })
    
    for (let index = 0; index < inputData.DataCountries.length; index++) {
      const data = inputData.DataCountries[index];
      this.myFormArrays.push(this.fb.group({
        CountryName: [data.CountryName, Validators.required],
        CountryDisplay: [data.CountryDisplay],
        CurrencyAccount: [data.CurrencyAccount, Validators.required],
        CommisionValue: [data.CommisionValue, Validators.required],
        CommissionCurrency: [data.CommissionCurrency, Validators.required],
        Spread: [data.Spread, [Validators.required,Validators.pattern("^[0-9.]{1,4}$")], Validators.maxLength(4)],
        FxSource: ['CURRENCYLAYER'],
        FxPeriod: [data.FxPeriod],
        LocalTax: [data.LocalTax,[Validators.minLength(1),Validators.maxLength(5),Validators.pattern("^[0-9.]{1,5}$")]],
        Currencies: this.fb.array([])
      }))
    }
    
  }


  ngOnInit() {
    this.clientData.refreshItemsBreadCrumb(this.itemsBreadcrumb)
    this.getCountries();
    this.getCurrencies();
    if(!this.inputData){
      this.initialCountries();
    }
  }

  ngAfterViewInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['inputData']){
      this.patchValueForm(changes.inputData.currentValue);
    }
  }

  getFormValidationErrors() {
    //Validate errors in form
    Object.keys(this.myForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.myForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
         console.log('[KeyControl]: ' + key + ', [KeyError]: ' + keyError + ', [ErrValue]: ', controlErrors[keyError]);
        });
      }
    });

    //Validate errors in form array
    Object.keys(this.myFormArrays.controls).forEach((index:any) => {
      const controlErrors: ValidationErrors = this.myFormArrays.get(index)['controls']
      Object.keys(controlErrors).forEach((keyControl)=>{
        if(controlErrors[keyControl].errors){
          Object.keys(controlErrors[keyControl].errors).forEach((keyError)=>{
            console.group("País - "+this.myFormArrays.controls[index].get('CountryName').value)
            console.log(`[Arr]['${index}'][KeyControl]: ${keyControl} [Arr]['${index}'][ActualValue]: '${this.myFormArrays.controls[index].get(keyControl).value}, [Arr]['${index}][KeyError]: ${keyError} , [Arr]['${index}'][ErrValue]: ', ${controlErrors[keyControl].errors[keyError]}`);
            console.groupEnd()
            
          })
        }
      })
    });
  }

  handleChangeCommisionValue(index: number){
    let comissionCurrencyValue = this.myFormArrays.controls[index].get('CommissionCurrency').value;
    let currencyAccountValue = this.myFormArrays.controls[index].get('CurrencyAccount').value;
    if(currencyAccountValue != 'USD'  && comissionCurrencyValue == 'USD' )
    this.myFormArrays.controls[index].get('CommisionValue').setValue("0");
  }

  handleChange(event: any, index: number){
    this.handleChangeCommisionValue(index);
    let countryCodeSelected = this.myFormArrays.controls[index].get('CountryName').value;
    let currencyAccountValue = this.myFormArrays.controls[index].get('CurrencyAccount').value;
    let comissionCurrencyValue = this.myFormArrays.controls[index].get('CommissionCurrency').value;
    
    this.ListMainCountries.forEach((data)=>{
      const localCurrency = data.Currency;
      const codeCountry = data.Code;
      
      switch(countryCodeSelected){

        case codeCountry :
          //Check validation case 1  - when currency account is local currency , the fields "Spread", "FxSource", "FxPerior" is disabled
          this.ruleOne(localCurrency,currencyAccountValue,index)
          //Check validation case 2  - when currency account is local currency  and comission account value is USD, the field value "ComissionValue" is 0
          this.ruleTwo(localCurrency,currencyAccountValue,comissionCurrencyValue,countryCodeSelected, index)
          //Check validation case 3  - when CountryName is GLOBAL the fiels "ComissionValue" , "ComissionAccount" , "FxSource" , "FxPeriod" is disabled
          this.ruleThree(countryCodeSelected,index)
        break;
        
      }

    })

  }


  ruleOne(localCurrency, currencyAccountValue, index){
    //when currency account is local currency , the fields "Spread", "FxSource", "FxPeriod" is disabled

    if(localCurrency == currencyAccountValue){
      this.myFormArrays.controls[index].get('Spread').setValue("0")
      this.myFormArrays.controls[index].get('Spread').setValidators(null)
      this.myFormArrays.controls[index].get('Spread').disable()
      this.myFormArrays.controls[index].get('Spread').updateValueAndValidity();

      this.myFormArrays.controls[index].get('FxSource').setValue("CURRENCYLAYER")
      this.myFormArrays.controls[index].get('FxSource').setValidators(null)
      this.myFormArrays.controls[index].get('FxSource').disable()
      this.myFormArrays.controls[index].get('FxSource').updateValueAndValidity();

      this.myFormArrays.controls[index].get('FxPeriod').setValue("1")
      this.myFormArrays.controls[index].get('FxPeriod').setValidators(null)
      this.myFormArrays.controls[index].get('FxPeriod').disable()
      this.myFormArrays.controls[index].get('FxPeriod').updateValueAndValidity();

    }else{

      this.myFormArrays.controls[index].get('Spread').setValidators([Validators.required,Validators.pattern("^[0-9.]{1,4}$"), Validators.maxLength(4)])
      this.myFormArrays.controls[index].get('Spread').enable()
      this.myFormArrays.controls[index].get('Spread').updateValueAndValidity();

      this.myFormArrays.controls[index].get('FxSource').setValidators([Validators.required])
      this.myFormArrays.controls[index].get('FxSource').enable()
      this.myFormArrays.controls[index].get('FxSource').updateValueAndValidity();

      this.myFormArrays.controls[index].get('FxPeriod').setValidators([Validators.required])
      this.myFormArrays.controls[index].get('FxPeriod').enable()
      this.myFormArrays.controls[index].get('FxPeriod').updateValueAndValidity();
    }
  }


  ruleTwo(localCurrency, currencyAccountValue, comissionCurrencyValue, countryCodeSelected, index){
    //when currency account is local currency  and comission currency value is USD, the field value "ComissionValue" is 0
    if(localCurrency == currencyAccountValue && comissionCurrencyValue == 'USD' && countryCodeSelected != 'ECU'){
      this.myFormArrays.controls[index].get('CommisionValue').patchValue("0")
      this.myFormArrays.controls[index].get('CommisionValue').updateValueAndValidity({emitEvent: true})
    }else{
      this.myFormArrays.controls[index].get('CommisionValue').updateValueAndValidity();
    }
  }

  ruleThree(countryCodeSelected, index){
    
    if(countryCodeSelected == 'GLO'){

      this.myFormArrays.controls[index].get('CommisionValue').setValidators(null)
      this.myFormArrays.controls[index].get('CommisionValue').disable();
      this.myFormArrays.controls[index].get('CommisionValue').setValue("0");
      this.myFormArrays.controls[index].get('CommisionValue').updateValueAndValidity();

      this.myFormArrays.controls[index].get('CommissionCurrency').setValidators(null);
      this.myFormArrays.controls[index].get('CommissionCurrency').disable();
      this.myFormArrays.controls[index].get('CommissionCurrency').setValue("USD");      
      this.myFormArrays.controls[index].get('CommissionCurrency').updateValueAndValidity();
      
      this.myFormArrays.controls[index].get('Spread').setValidators(null);
      this.myFormArrays.controls[index].get('Spread').disable();
      this.myFormArrays.controls[index].get('Spread').setValue("0");
      this.myFormArrays.controls[index].get('Spread').updateValueAndValidity();

    }
  }
    


  checkAccount(){

    const payload = {
      merchantName: this.myForm.get('MerchantName').value,
      email: this.myForm.get('Mail').value,
    }
    this.securityService.checkAccountIfExists(payload).subscribe(response =>{
      if(response){
        this.accountExists = true;
        this._toast.error("This merchant name or email already exists");
        return;
      }
      this.accountExists = false;

    },
    error =>{console.log(error)})
  }

  getCountries() {
    environment.Countries.filter(data => data.active === true).map((item:any) => {
      
      let country = new Country({
        Code: item.Code,
        Name: item.Name,
        Currency: item.Currency,
        FlagIcon: item.FlagIcon,
        NameCode: item.NameCode,
        Description: item.Description,
        Currencies: item.currencies,
        LocalTax: item.LocalTax
      })
      country.addIcon()
      this.ListMainCountries.push(country)
      this.ListCountries.push(country)
    });
  }

  getCurrencies() {
    environment.Countries.filter(data => data.active === true).map((item) => {
      let currencies = new CountryCurrency({
        Code: item.Code,
        Name: item.Name,
        Currencies: item.currencies
      })
      this.ListMainCurrencies.push(currencies)
    });
  }

  addCountriesAfterRemove(code) {
    environment.Countries.filter(data => data.Code == code && data.active === true).map((item) => {
      let country = new Country({
        Code: item.Code,
        Name: item.Name,
        Currency: item.Currency,
        FlagIcon: item.FlagIcon,
        NameCode: item.NameCode,
        Description: item.Description,
        Currencies: item.currencies
      })
      country.addIcon()
      this.ListCountries.unshift(country)
    });
  }

  


  changeCountry(e,i){
    let countryCodeSelected = this.myFormArrays.controls[i].get('CountryName').value;
    this.myFormArrays.controls[i].get('CountryDisplay').setValue(e.Name);
    
    let countryCurrencies = ((this.myForm.get('DataCountries') as FormArray).at(i).get('Currencies') as FormArray);
    countryCurrencies.reset()

    let filtered = this.ListMainCountries.filter(data => data.Code == countryCodeSelected);
    this.myFormArrays.controls[i].get('LocalTax').setValue(filtered[0].LocalTax);
    
    for (let index = 0; index < filtered[0].Currencies.length; index++) {
      const element = filtered[0].Currencies[index];
      countryCurrencies.push(this.fb.group({Name: element.name}))
    }

    let tmpArr = [];
    for(let index = 0 ; index < this.myFormArrays.controls.length; index++){
      let arrayValue = this.myFormArrays.controls[index].get('CountryName').value;
      tmpArr.push(arrayValue)
    }
    this.ListCountriesSelected = tmpArr;
    this.ListCountries = this.ListMainCountries.filter(data => this.ListCountriesSelected.indexOf(data.Code) === -1);

  }
  

  loadCurrencies(code : any){
    this.ListCurrenciesFilter = this.ListMainCurrencies.filter(e => e.Code == code);
  }

  //Methods to array forms

  //Get
  get myFormArrays(): FormArray {
    return this.myForm.get("DataCountries") as FormArray
  }

  //New Form
  newCountryArray(): FormGroup {
    return this.fb.group({
      CountryName: ['', Validators.required],
      CountryDisplay: ['',],
      CurrencyAccount: ['', Validators.required],
      CommisionValue: ['', [Validators.required,Validators.minLength(1), Validators.maxLength(4), Validators.pattern("^[0-9.]{1,4}$")]],
      CommissionCurrency: ['', Validators.required],
      Spread: ['', [Validators.required,Validators.minLength(1), Validators.maxLength(4), Validators.pattern("^[0-9.]{1,4}$")]],
      FxSource: ['CURRENCYLAYER',[Validators.required]],
      FxPeriod: ['',Validators.required],
      LocalTax: ['',[Validators.minLength(1), Validators.maxLength(5),Validators.pattern("^[0-9.]{1,5}$")]],
      Currencies: this.fb.array([])
    })
  }

  newCountryArray2(country: any, countryName: any,LocalTax: any): FormGroup {
    return this.fb.group({
      CountryName: [country, Validators.required],
      CountryDisplay: [countryName],
      CurrencyAccount: ['', Validators.required],
      CommisionValue: ['', [Validators.required,Validators.minLength(1), Validators.maxLength(4), Validators.pattern("^[0-9.]{1,4}$")]],
      CommissionCurrency: ['', Validators.required],
      Spread: ['', [Validators.required,Validators.minLength(1), Validators.maxLength(4), Validators.pattern("^[0-9.]{1,4}$")]],
      FxSource: ['CURRENCYLAYER',Validators.required],
      FxPeriod: ['',Validators.required],
      LocalTax: [LocalTax,[Validators.minLength(1), Validators.maxLength(5),Validators.pattern("^[0-9.]{1,5}$")]],
      Currencies: this.filteredCurrencies(country)
    })
  }

  currencyCountry () : FormGroup{
    return this.fb.group({
      Name: ['USD'],
    })
  }

  filteredCurrencies(code: any){
    let filtered = this.ListMainCountries.filter(data => data.Code == code).map(data => data.Currencies);
    let tmpArr = filtered[0].map((data)=>{
      return this.fb.group({
        Name: data.name,
      })
    })
    return this.fb.array(tmpArr);
  }
  
  

  initialCountries(){
    for (let index = 0; index < this.ListCountries.length; index++) {
      const element = this.ListCountries[index];
      console.log("mostrando element initialcountries", element)
      this.myFormArrays.push(this.newCountryArray2(element.Code, element.Name, element.LocalTax));
      this.ListCountriesSelected.push(element.Code);
    }
    setTimeout(()=>{this.ListCountries = this.ListMainCountries.filter(data => this.ListCountriesSelected.indexOf(data.Code) === -1);},500)
  }

  //Add
  addCountry() {
    this.ListCountries = this.ListCountries.filter(data => this.ListCountriesSelected.indexOf(data.Code) === -1);
    this.myFormArrays.push(this.newCountryArray());
  }

  //Remove
  removeCountry(i: number) {
    //get actual countryCode Selected
    let countryCode = this.myFormArrays.controls[i].get('CountryName').value;
    if(countryCode){
      this.ListCountriesSelected = this.ListCountriesSelected.filter(data => data != countryCode);
    }
    this.addCountriesAfterRemove(countryCode);
    this.ListCountries = this.ListMainCountries.filter(data => this.ListCountriesSelected.indexOf(data.Code) === -1);
    
    this.myFormArrays.removeAt(i);
  }

  //Copy to clipboard
  copyInputMessage(inputElement) {  
    navigator.clipboard.writeText(inputElement.value).then(() => {
        this.copied = true;
        setTimeout(() => { this.copied = false; },500)
    });
  } 

  //Send data to api

  submit(){
    
    this.checkAccount();

    if(!this.accountExists){
      this.loading = true;

    let payload = this.myForm.value;
    
    payload.Countries = this.ListCountriesSelected;
    
    let resultDataCountries = payload.DataCountries.map((data)=>{
      return{
        FxPeriod: data.FxPeriod ? parseInt(data.FxPeriod) : parseInt("0"),
        CommisionValue: data.CountryName == 'GLO' ? parseInt("0") : parseFloat(data.CommisionValue),
        CommissionCurrency: data.CountryName == 'GLO' ? "USD" : data.CommissionCurrency,
        CountryDisplay: data.CountryDisplay,
        CountryName: data.CountryName,
        CurrencyAccount: data.CurrencyAccount,
        LocalTax: parseFloat(data.LocalTax),
        Spread: data.Spread ? parseFloat(data.Spread) : parseInt("0"),
        AfipRetention: '',
        ArbaRetention: ''
      }
    })
    delete payload.DataCountries;
    payload.DataCountries = resultDataCountries;
    this.securityService.createAccount(payload).subscribe(response => {
      this.loading = false;
      this._toast.success('Account created succesfully');
      this.myForm.reset();
      this.responseAPI = response[0];
      this.AccountDataTextArea = `Client Name: ${response[0].MerchantName}\n\nApi Credential\nCustomer_ID: ${response[0].Identification}\nApi_Key: ${response[0].ApiPassword}\n\nFrontend Credential\nMail: ${response[0].Mail}\nPassword: ${response[0].WebPassword}\nUrl: ${this.locationUrl}/Client`

      }, error =>{
      this.loading = false;
      this._toast.error('Error when intent create account.');
      console.log(error);
    })

    }
    
  }



}

export class Currency {
  name: string

  constructor(_name: string){
    this.name = _name;
  }
}

export class CountryCurrency {
  Code: string;
  Name: string;
  Currencies: Array<any>

  constructor(_country: any) {
      this.Code = _country.Code || null;
      this.Name = _country.Name || null;
      this.Currencies = _country.Currencies || null;
  }

}
