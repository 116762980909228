import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class OrchestratorService {

  public _urlRouter : string = "";
  private _controllerName :string = "";

  constructor(private _http : HttpClient) {
    this._urlRouter = environment.urlRouter+'/api';
    this._controllerName = "paymentlogs"
  }

  

  getAll(filters: any = {}): Observable<any> {
    let params = new HttpParams();

    // Agregar parámetros de filtrado si existen
    if (filters.searchTerm) {
      params = params.set('searchTerm', filters.searchTerm);
    }
    if (filters.country) {
      params = params.set('country', filters.country);
    }
    if (filters.startDate) {
      params = params.set('startDate', filters.startDate);
    }
    if (filters.endDate) {
      params = params.set('endDate', filters.endDate);
    }
    if (filters.timeZone) {
      params = params.set('timeZone', filters.timeZone);
    }

  return this._http.get<any>(`${this._urlRouter}/${this._controllerName}/`, { params })
      .pipe(catchError(this.handleErrors));
  }

  getById(id: number): Observable<any>{
    return this._http.get<any>(`${this._urlRouter}/${this._controllerName}/${id}`).pipe(catchError(this.handleErrors))
  }


  private handleErrors(error: HttpErrorResponse){
    if(error.error instanceof ErrorEvent){
      console.log("Ocurrio un error", error.error.message);
    }else{
      console.log(`Ocurrio un error ${error.status}, ${error.error}`);
    }
    return throwError(() => new Error('Algo salió mal; por favor, intente nuevamente más tarde.'));
  }
}
